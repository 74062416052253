<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> レポート - 受注済み一覧</h1>
    </header>

    <!-- 検索欄 -->
    <section class="section">
        <div class="row align-items-end">
            <div class="col-12">
                <label class="form-label">事業部</label>
                <form-select-search-multiple
                    v-model="condition.department_ids"
                    :options="options_department"
                >
                </form-select-search-multiple>
            </div>
            <div class="col-10">
                <label class="form-label">集計期間</label>
                <div class="input-group">
                    <form-input-date
                        v-model="condition.date_from"
                    ></form-input-date>
                    <span class="input-group-text">〜</span>
                    <form-input-date
                        v-model="condition.date_to"
                    ></form-input-date>
                </div>
            </div>
            <div class="col-6">
                <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <template v-if="orders.length">
                <div class="mx-1 my-3 px-3 d-flex align-items-center">
                    <p class="my-0 me-5">合計 : {{ order_count.total }} 件</p>
                    <p class="my-0 me-5">大手企業 : {{ order_count.is_major_total }} 件</p>
                    <p class="my-0 me-5">その他規格 :  {{ order_count.competitor_note_total }} 件</p>
                    <p class="my-0">反響受注件数から除外 : {{ order_count.not_count_total }} 件</p>
                </div>
                <div class="table-responsive mb-3" style="width: 100%;">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="table-dark">
                                <th class="text-center text-nowrap">受注日</th>
                                <th class="text-center"></th>
                                <th class="text-center text-nowrap">事業部</th>
                                <th class="text-center text-nowrap">会社名</th>
                                <th class="text-center text-nowrap">大手企業</th>
                                <th class="text-center text-nowrap">確実度</th>
                                <th class="text-center text-nowrap">優先度</th>
                                <th class="text-center text-nowrap">見込日</th>
                                <th class="text-center text-nowrap">評価</th>
                                <th class="text-center text-nowrap">役職</th>
                                <th class="text-center text-nowrap">営業担当</th>
                                <th class="text-center text-nowrap">チャネル</th>
                                <th class="text-center text-nowrap">追客担当</th>
                                <th class="text-center text-nowrap">競合</th>
                                <th class="text-center text-nowrap">競合（その他）</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="projects in orders" :key="projects.ordered_date">
                                <template v-for="(project, index) in projects.projects" :key="project.project_id">
                                    <tr>
                                        <template v-if="index === 0">
                                            <td class="align-middle px-4 text-nowrap text-center" :rowspan="projects.projects.length">{{ project.ordered_date_label }}</td>
                                            <td class="align-middle px-4 text-nowrap text-end" :rowspan="projects.projects.length">{{ projects.projects.length }}</td>
                                        </template>
                                        <td class="align-middle text-nowrap px-4">{{ project.department.department_name }}</td>
                                        <td class="align-middle text-nowrap px-4">{{ project.customer.company_name }}</td>
                                        <td class="align-middle px-4 text-center">{{ project.customer.is_major_label }}</td>
                                        <td class="align-middle text-nowrap px-4">{{ project.certainty?.certainty_name }}</td>
                                        <td class="align-middle text-nowrap px-4">{{ project?.probability.probability_name }}</td>
                                        <td class="align-middle text-nowrap px-4">{{ project.expected_order_date_label }}</td>
                                        <td class="align-middle text-nowrap px-4">{{ project.rank_label }}</td>
                                        <td class="align-middle text-nowrap px-4">
                                            <template v-if="project.primary_contact">
                                                {{ project.primary_contact.position }}
                                            </template>
                                        </td>
                                        <td class="align-middle text-nowrap px-4">{{ project.sales_user.user_name }}</td>
                                        <td class="align-middle text-nowrap px-4">{{ project.channel?.channel_name }}</td>
                                        <td class="align-middle text-nowrap px-4">{{ project.follow_user.user_name }}</td>
                                        <td class="align-middle px-4" style="white-space: pre;">{{ project.competitors_label }}</td>
                                        <td class="align-middle px-4">{{ project.competitor_note }}</td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
            </template>
            <template v-else>
                <p class="alert alert-secondary">※受注済みレポートがありません</p>
            </template>
        </template>
        <!-- ページネーション -->
        <paginator :meta="paginator" @move="search" class="mt-5"></paginator>
    </section>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInputDate from '@/components/forms/FormInputDate';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import Paginator from '@/components/tools/Paginator';
import Project from '@/models/entities/project';
import Department from '@/models/entities/department';
import CountType from '@/models/enums/count-type';

export default {
    name: 'ReportOrder',
    components: {
        InlineLoader,
        FormInputDate,
        Paginator,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // ページネーション
            paginator: {},

            // 検索条件
            condition: {
                department_ids: [],
                date_from: null,
                date_to: null,
                page: 1,
                per_page: null,
            },

            options_department: [],

            // 表示データ
            orders: [],
            order_count: [],
            projects: [],

            // Enum
            CountType,
        }
    },
    mounted() {
        this.setInitialDate();
        this.init();
    },
    methods: {
        // 検索期間の初期値設定
        setInitialDate () {
            let date_obj = new Date();
            let to_year = date_obj.getFullYear().toString();
            let to_month = (date_obj.getMonth() + 1).toString().padStart(2, '0');
            let to_date = date_obj.getDate().toString().padStart(2, '0');
            this.condition.date_to = to_year + '-' + to_month + '-' + to_date;

            date_obj.setDate(date_obj.getDate() - 6);
            let from_year = date_obj.getFullYear().toString();
            let from_month = (date_obj.getMonth() + 1).toString().padStart(2, '0');
            let from_date = date_obj.getDate().toString().padStart(2, '0');
            this.condition.date_from = from_year + '-' + from_month + '-' + from_date;
        },
        async init() {
            await this.fetchDepartments();
            this.search();
        },
        fetchDepartments() {
            return new Promise(resolve => {
                this.loading_count++;
                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        const department = new Department(row);
                        this.options_department.push({value: department.department_id, label: department.department_name});
                    }

                    //事業部未登録の場合
                    if (this.options_department.length === 0) {
                        this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    }
                    if (this.$store.state.auth.department_id) {
                        // 自身の事業部を設定する
                        this.condition.department_ids.push(this.$store.state.auth.department_id);
                    } else {
                        // 所属する事業部がなければ1つ目の事業部を設定
                        this.condition.department_ids.push(this.options_department[0].value);
                    }
                })
                .finally(() => {
                    this.loading_count--;
                    resolve(true);
                })
            })
        },
        // 検索
        search(page = 1) {
            this.condition.page = page;

            // 集計期間バリデーション
            if (this.invalidDate()) {
                return;
            }

            // 受注済み一覧の件数リスト取得
            this.fetchOrder();

            // 受注済み一覧 件数カウント値取得
            this.fetchOrderCount();
        },
        invalidDate() {
            if (
                this.$helper.isBlank(this.condition.date_from)
                || this.$helper.isBlank(this.condition.date_to)
            ) {
                this.showErrorMessage('集計期間を設定してください');
                return true;
            }
        },
        // 受注済み一覧の件数リスト取得
        fetchOrder() {
            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.orders.splice(0);

            this.$http.get('/report/order', {
                params: this.condition,
            })
            .then(response => {
                const orders = response.data;

                let ordered_dates = orders.map((project) => {
                    return project.ordered_date;
                });
                ordered_dates = new Set(ordered_dates);

                ordered_dates.forEach((ordered_date) => {
                    let filtered_orders = orders.map((order) => {
                        if (order.ordered_date === ordered_date) {
                            return new Project(order);
                        }
                    }).filter(Boolean);
                    this.orders.push({
                        ordered_date: ordered_date,
                        projects: filtered_orders
                    });
                });

                // ページネーション用
                this.paginator = response.data.meta;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
        fetchOrderCount() {
            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.order_count = [];

            this.$http.get('/report/order/count', {
                params: this.condition,
            })
            .then(response => {
                this.order_count = response.data;
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
    }
}
</script>

<style scoped>

</style>
